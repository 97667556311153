<script>
export default {
  name: 'Marquee',

  data () {
    return {
      items: undefined
    }
  },

  watch: {
    $route () {
      this.getData()
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    async getData () {
      let type
      let route = 'combined-statistics'
      if (this.$route.name.startsWith('index__')) {
        route = 'marketplace-statistics'
        type = 'marketplace'
      } else if (this.$route.name.startsWith('missions__')) {
        route = 'offerwall-statistics'
        type = 'offerwall_payment'
      } else if (this.$route.name.includes('game')) {
        route = 'games-statistics'
        type = 'tournament_prize'
      }
      try {
        const { data } = await this.$axios.get(
          `https://api.gaming-goods.com/platform/${route}`
        )

        if (type) {
          this.items = data.data.map(item => ({ ...item, type }))
        } else {
          this.items = data.data
        }
      } catch (e) {
        console.error(`${new Date().toUTCString()} :: offerwall live stats error ::`, e)
      }
    },

    getItemType (itemType) {
      const types = {
        offerwall_payment: 'offerwall',
        tournament_prize: 'tournament',
        duel_prize: 'duel',
        marketplace: 'marketplace'
      }

      return types[itemType]
    },

    getItemCover (item) {
      if (item.type === 'offerwall_payment' && ['AYET', 'BITLABS', 'CPX', 'INBRAIN', 'LOOTABLY', 'OFFERTORO', 'POLLFISH', 'NOTIK', 'MONLIX', 'ADGATE', 'MOBSUCCESS', 'OFFERDADDY', 'LUCKYWALL', 'MAKEMONEY', 'MYLEAD', 'REVLUM', 'OVALIO'].includes(item.offerwall_name)) {
        return `https://cdn.gaming-goods.com/images/stats/offerwalls/${item.offerwall_name}.webp`
      } else if (item.type === 'offerwall_payment' && ['MYCHIPS'].includes(item.offerwall_name)) {
        return `https://cdn.gaming-goods.com/images/stats/offerwalls/${item.offerwall_name}.png`
      }

      return item.avatar
    },

    itemText (item) {
      if (item.type.includes('marketplace')) {
        return `${this.$t('purchase')} - ${item.alias} ${this.$t(item.full_name)}`
      }
      return item.full_name
    },

    onClick (item) {
      if (item.type.includes('marketplace')) {
        window.open(`${this.$config.WWW_HOST}/marketplace/product/${item.id}`, '_blank')
      }
      if (item.type === 'offerwall_payment') {
        window.open(`${this.$config.WWW_HOST}/missions`, '_blank')
      }
      window.open(`${this.$config.WWW_HOST}/user/${item.alias}`, '_blank')
    }
  }
}
</script>

<template>
  <div :class="$style.wrap">
    <div v-if="items" :class="$style.itemsWrap">
      <div :class="[$style.items, $style.marquee]">
        <div v-for="(item, i) in items" :key="`${item.id}-${i}`" :class="$style.item" @click="onClick(item)">
          <img
            :src="getItemCover(item)"
            alt="cover"
            :class="$style.itemCover"
            height="26"
            width="26"
            loading="lazy"
          >
          <div :class="$style.itemBody">
            <div :class="$style.itemType">
              {{ $t(getItemType(item.type)) }}
            </div>
            <div :class="$style.itemName">
              {{ itemText(item) }}
            </div>
          </div>
          <div :class="$style.itemCost">
            <Currency type="money" size="12" is-reversed :real="!item.is_g_cash">
              <span :class="$style.itemCostValue" v-html="(Math.abs(item.money / 100)).toFixed(2).replace('.00', '')" />
            </Currency>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.item {
  flex: 0 0 auto;
  display: grid;
  grid-template-columns: 26px 1fr max-content;
  grid-gap: 4px;
  height: 44px;
  align-items: center;
  background: $blue-grey60;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  counter-increment: item;
  cursor: pointer;

  &Cover {
    width: 26px;
    height: 26px;
    margin: 4px 0 4px 4px;
  }

  &Body {
    padding: 4px;
  }

  &Type {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $white;
  }

  &Name {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $blue-grey10;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Cost {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    background: linear-gradient(179.69deg, rgba(76, 175, 80, 0) 0.29%, rgba(76, 175, 80, 0.125) 99.75%);
    padding: 10px 6px;

    &Value {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $green50;
    }
  }
}

.wrap {
  height: 100%;
  max-width: 100vw;
  margin: auto;
}
.itemsWrap {
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 4px;
}
.itemsWrap:before,
.itemsWrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.itemsWrap:before {
  left: 0;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
  );
}
.itemsWrap:after {
  right: 0;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
  );
}
.items {
  flex-shrink: 0;
  display: flex;
  gap: 4px;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
}

.item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.marquee {
  animation: scroll 400s linear infinite;
}
.reverce {
  animation-direction: reverse;
}
.itemsWrap:hover .marquee {
  animation-play-state: paused;
}

.perfscan {
  margin: 20px 0;
  text-align: center;
  bottom: 0;
  background: #fff;
  padding: 5px;
}
.perfscan hr {
  border: solid #999;
  border-width: 1px 0 0 0;
  max-width: 50%;
  margin: 0 auto 20px;
}
.perfscan a {
  color: #000;
  font-weight: bold;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

</style>
